(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/bet/board-1x2.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/bet/board-1x2.js');

'use strict';

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  BoardPoolgames
} = svs.components.payment.createWager.bet;
const {
  Outcomes1X2,
  SystemType
} = svs.components.payment.common.constants;
const {
  WagerError
} = svs.components.payment.common.error;
const {
  systems
} = svs.components.sport;
const {
  oneXTwoToBinary
} = svs.components.payment.createWager.utils;
const asStringArray = array => "[".concat(array.join(','), "]");
var _mathHedge = new WeakMap();
var _uOutcomes = new WeakMap();
class Board1x2 extends BoardPoolgames {
  constructor() {
    super(...arguments);
    _classPrivateFieldInitSpec(this, _mathHedge, []);
    _classPrivateFieldInitSpec(this, _uOutcomes, []);
  }
  setSystemNumber(systemNumber) {
    if (!systems.getSystemFromNumber(systemNumber)) {
      throw new WagerError({
        message: "Unsupported systemNumber: ".concat(systemNumber),
        clientCode: WagerError.ErrorCodes.UNSUPPORTED_VALUE_SYSTEM_NUMBER
      });
    }
    super.setSystemNumber(systemNumber);
    return this;
  }
  getRowCount() {
    let rowCount = super.getRowCount();
    if (_classPrivateFieldGet(_mathHedge, this).length) {
      _classPrivateFieldGet(_mathHedge, this).forEach(hedge => {
        const eventSignsCount = this.outcomes[hedge - 1].reduce((sum, outcomes) => sum + outcomes.length, 0);
        rowCount = rowCount * eventSignsCount;
      });
    }
    return rowCount;
  }

  getBoardData() {
    const outcomes = this.outcomes.map(outcomes => outcomes.slice().pop().map(outcome => [Outcomes1X2.ONE, Outcomes1X2.X, Outcomes1X2.TWO][outcome]).join(''));
    if ((this.systemType === SystemType.RSYS || this.systemType === SystemType.USYS) && _classPrivateFieldGet(_mathHedge, this).length) {
      _classPrivateFieldGet(_mathHedge, this).forEach(hedgeEventNumber => {
        if (!outcomes[hedgeEventNumber - 1].length > 1) {
          throw new WagerError({
            message: "Can only set math hedge to event with more than 1 outcome: ".concat(outcomes[hedgeEventNumber - 1]),
            clientCode: WagerError.ErrorCodes.INCOMPLETE_BET
          });
        }
        outcomes[hedgeEventNumber - 1] = "".concat(outcomes[hedgeEventNumber - 1], "M");
      });
    }
    return outcomes;
  }

  addMathHedge(eventNumber) {
    _classPrivateFieldGet(_mathHedge, this).push(eventNumber);
    return this;
  }
  get mathHedges() {
    return _classPrivateFieldGet(_mathHedge, this);
  }

  addUOutcome(eventNumber, outcomeIndex) {
    if (!_classPrivateFieldGet(_uOutcomes, this).length || _classPrivateFieldGet(_uOutcomes, this).length < eventNumber) {
      const newArrayLength = eventNumber - _classPrivateFieldGet(_uOutcomes, this).length;
      _classPrivateFieldSet(_uOutcomes, this, _classPrivateFieldGet(_uOutcomes, this).concat(new Array(newArrayLength).fill(undefined)));
    }
    _classPrivateFieldGet(_uOutcomes, this).splice(eventNumber - 1, 1, outcomeIndex);
    return this;
  }
  get uOutcomes() {
    return _classPrivateFieldGet(_uOutcomes, this);
  }

  toString() {
    const logString = super.toString();
    const outcomeString = this.outcomes.map(board => board.map(selection => asStringArray(selection))).join(',');
    const logEntries = ["B:".concat(outcomeString), logString];
    if (this.systemType === SystemType.USYS && _classPrivateFieldGet(_uOutcomes, this).length) {
      const uOutcomeString = this.uOutcomes.map((uOutcome, index) => uOutcome !== undefined ? "".concat(index + 1, ":").concat(uOutcome) : undefined).filter(value => value).join(',');
      logEntries.push("UB:".concat(uOutcomeString));
    }
    if (this.systemNumber && _classPrivateFieldGet(_mathHedge, this).length) {
      logEntries.push("MH:[".concat(_classPrivateFieldGet(_mathHedge, this).join(','), "]"));
    }
    return logEntries.join(' ');
  }

  compose() {
    const boardData = this.getBoardData();
    return {
      boardData,
      boardProps: this.boardProperties,
      systemType: [this.systemType],
      systemNum: this.systemNumber || 1
    };
  }

  composeUOutcomes() {
    if (this.systemType === SystemType.USYS) {
      const uSigns = this.uOutcomes;
      const uBoard = {};
      uBoard.boardData = this.outcomes.map((outcome, index) => {
        if (uSigns[index] !== undefined) {
          return [Outcomes1X2.ONE, Outcomes1X2.X, Outcomes1X2.TWO][uSigns[index]];
        }
        return '0';
      });
      uBoard.systemType = [SystemType.UROW];
      return uBoard;
    }
  }
  composeOwnRows() {
    const boardData = this.getBoardData();
    const convertedBoardData = boardData.map(outcome => oneXTwoToBinary(outcome)).join('');
    return convertedBoardData;
  }
}
setGlobal('svs.components.payment.createWager.bet.Board1x2', Board1x2);

 })(window);