(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/bet/bet-1x2.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/bet/bet-1x2.js');
'use strict';

const {
  Bet
} = svs.components.payment.createWager.bet;
const {
  systems
} = svs.components.sport;
const {
  SystemType,
  BetProperties
} = svs.components.payment.common.constants;
class Bet1X2 extends Bet {
  getSystemLabel() {
    var _this$boards, _this$betProperties, _this$boards2;
    if ((_this$boards = this.boards) !== null && _this$boards !== void 0 && _this$boards[0].systemNumber) {
      return systems.getSystemKeyFromNumber(this.boards[0].systemNumber);
    }
    if ((_this$betProperties = this.betProperties) !== null && _this$betProperties !== void 0 && _this$betProperties.includes(BetProperties.REDUCE_FREE)) {
      return "reducera fritt ".concat(this.getRowCount());
    }
    const systemType = (_this$boards2 = this.boards) === null || _this$boards2 === void 0 ? void 0 : _this$boards2[0].systemType;
    if (systemType === SystemType.MSYS) {
      return "M ".concat(this.getRowCount());
    }
    if (systemType === SystemType.SROW) {
      return "S ".concat(this.getRowCount());
    }
    return super.getSystemLabel();
  }
  composeOwnRowsBoards() {
    const boards = this.boards.map(board => board.composeOwnRows());
    return {
      er1X2BoardData: {
        simpleRowsOnly: this.isSingleRowsOnly,
        boards
      }
    };
  }
  composeBoards() {
    const game1X2Boards = this.boards.reduce((boards, board) => {
      const newBoards = [];
      newBoards.push(board.compose());
      if (board.systemType === SystemType.USYS) {
        const uBoard = board.composeUOutcomes();
        uBoard && newBoards.push(uBoard);
      }
      return [...boards, ...newBoards];
    }, []);
    return {
      game1X2Boards
    };
  }
}
setGlobal('svs.components.payment.createWager.bet.Bet1X2', Bet1X2);

 })(window);