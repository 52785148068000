(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/bet/bet.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/bet/bet.js');
'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _classPrivateMethodInitSpec(e, a) { _checkPrivateRedeclaration(e, a), a.add(e); }
function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  WagerError
} = svs.components.payment.common.error;
const {
  BoardProperties,
  BetProperties,
  QuickPick,
  SystemType,
  subProductIds
} = svs.components.payment.common.constants;
const {
  getProductConfig
} = svs.components.payment.createWager.configs;
const {
  CurrencyAsJupiterString
} = svs.utils.format;
const {
  Board,
  BoardScore,
  Board1x2,
  BoardNumboards,
  BoardRx,
  BoardSportkryss,
  BoardFulltraff
} = svs.components.payment.createWager.bet;
const {
  NotImplementedError
} = svs.components.payment.common.error;
const {
  products
} = svs.utils;
const {
  BOMBEN,
  STRYKTIPSET,
  MATCHEN,
  T8,
  T8EXTRA,
  T8STRYK,
  EUROPATIPSET,
  FULLTRAFF,
  MALTIPSET,
  POWERPLAY,
  RACING,
  CHALLENGE,
  SPORTKRYSS
} = products.productIds;
const addonProductId = [SPORTKRYSS];
const addonProductName = {
  [SPORTKRYSS]: 'SPORTJOKER'
};

var _boards = new WeakMap();
var _betProperties = new WeakMap();
var _productId = new WeakMap();
var _rowPrice = new WeakMap();
var _drawNumber = new WeakMap();
var _numberOfDraws = new WeakMap();
var _subProductId = new WeakMap();
var _Bet_brand = new WeakSet();
class Bet {
  constructor(productId) {
    _classPrivateMethodInitSpec(this, _Bet_brand);
    _classPrivateFieldInitSpec(this, _boards, []);
    _classPrivateFieldInitSpec(this, _betProperties, new Set());
    _classPrivateFieldInitSpec(this, _productId, void 0);
    _classPrivateFieldInitSpec(this, _rowPrice, 1);
    _classPrivateFieldInitSpec(this, _drawNumber, 0);
    _classPrivateFieldInitSpec(this, _numberOfDraws, 1);
    _classPrivateFieldInitSpec(this, _subProductId, void 0);
    if (!productId) {
      throw new WagerError({
        message: 'Missing productId for Bet',
        clientCode: WagerError.ErrorCodes.INCOMPLETE_BET
      });
    }
    _classPrivateFieldSet(_productId, this, productId);
    const [minPrice] = getProductConfig(productId).rowPrice;
    _classPrivateFieldSet(_rowPrice, this, minPrice);
  }
  addBoard(boardBuilderFn) {
    let board;
    switch (_classPrivateFieldGet(_productId, this)) {
      case BOMBEN:
      case MATCHEN:
        board = new BoardScore(_classPrivateFieldGet(_productId, this));
        break;
      case FULLTRAFF:
        board = new BoardFulltraff(_classPrivateFieldGet(_productId, this));
        break;
      case STRYKTIPSET:
      case T8:
      case T8EXTRA:
      case T8STRYK:
      case EUROPATIPSET:
      case POWERPLAY:
      case CHALLENGE:
        board = new Board1x2(_classPrivateFieldGet(_productId, this));
        break;
      case MALTIPSET:
        board = new BoardNumboards(_classPrivateFieldGet(_productId, this));
        break;
      case RACING:
        board = new BoardRx(_classPrivateFieldGet(_productId, this));
        break;
      case SPORTKRYSS:
        board = new BoardSportkryss(_classPrivateFieldGet(_productId, this));
        break;
      default:
        board = new Board(_classPrivateFieldGet(_productId, this));
    }
    boardBuilderFn(board);
    _classPrivateFieldGet(_boards, this).push(board);
    return this;
  }

  setSubProductId(subProductId) {
    _classPrivateFieldSet(_subProductId, this, subProductId);
    return this;
  }

  addBetProperty(betProperty) {
    const validProperties = Object.values(Bet.BetProperties);
    if (!validProperties.includes(betProperty)) {
      throw new WagerError({
        message: "Invalid board property: ".concat(betProperty, ", valid: [").concat(validProperties.join(', '), "]"),
        clientCode: WagerError.ErrorCodes.UNSUPPORTED_VALUE_BET_PROP
      });
    }
    _classPrivateFieldGet(_betProperties, this).add(betProperty);
    return this;
  }

  setRowPrice(rowPrice) {
    const [minPrice, maxPrice] = this.productConfig.rowPrice;
    if (rowPrice % minPrice !== 0) {
      throw new WagerError({
        message: "Invalid row price: ".concat(rowPrice, ", min: ").concat(minPrice, ", max: ").concat(maxPrice, ". Should be evenly divided with minPrice"),
        clientCode: WagerError.ErrorCodes.UNSUPPORTED_VALUE_ROW_PRICE
      });
    }
    if (rowPrice < minPrice || rowPrice > maxPrice) {
      throw new WagerError({
        message: "Invalid row price: ".concat(rowPrice, ", min: ").concat(minPrice, ", max: ").concat(maxPrice),
        clientCode: WagerError.ErrorCodes.UNSUPPORTED_VALUE_ROW_PRICE
      });
    }
    _classPrivateFieldSet(_rowPrice, this, rowPrice);
    return this;
  }

  setDrawNumber(drawNumber) {
    _classPrivateFieldSet(_drawNumber, this, drawNumber);
    return this;
  }
  setRowPriceMulti() {
    throw new NotImplementedError();
  }

  setNumberOfDraws(numberOfDraws) {
    if (!this.productConfig.numberOfDraws.includes(numberOfDraws)) {
      throw new WagerError({
        message: "Invalid draw count: ".concat(numberOfDraws, ", valid: ").concat(this.productConfig.numberOfDraws),
        clientCode: WagerError.ErrorCodes.UNSUPPORTED_VALUE_DRAW_COUNT
      });
    }
    _classPrivateFieldSet(_numberOfDraws, this, numberOfDraws);
    return this;
  }

  getSystemLabel() {
    throw new NotImplementedError();
  }

  composeBoards() {
    throw new NotImplementedError();
  }

  composeOwnRowsBoards() {
    throw new NotImplementedError();
  }

  getRowCount() {
    return this.boards.reduce((sum, board) => board.getRowCount() + sum, 0);
  }

  compose() {
    const boards = _assertClassBrand(_Bet_brand, this, _getBoards).call(this);
    const additionalBetProperties = {};
    if (this.betProperties.length) {
      additionalBetProperties.betProperties = this.betProperties;
    }
    const itsProductId = addonProductId.includes(this.productId) ? addonProductName[this.productId] : this.itsProductId;
    return Object.assign(_objectSpread({
      productId: itsProductId,
      drawNum: this.drawNumber,
      numDraws: this.numberOfDraws,
      betAmountSalesGroup1: this.priceAsJupiterString,
      betAmountSalesGroup2: '',
      rowPriceMulti: this.rowPriceMulti
    }, additionalBetProperties), boards);
  }

  toString() {
    let boardsStrings;
    if (this.isOwnRows || this.boards.length > 1 && this.boards[0].systemType === SystemType.SROW) {
      boardsStrings = ["".concat(this.boards[0].systemType, ":(").concat(this.systemSize, ")")];
    } else {
      boardsStrings = this.boards.map(board => board.toString(this.isOwnRows));
    }
    const logEntries = ["pID:".concat(this.productId), "D:".concat(this.drawNumber), "".concat(boardsStrings.join('|')), "RP:".concat(this.rowPrice), "BP:".concat(this.betProperties.join('|')), "R:".concat(this.numberOfDraws)];
    return logEntries.join(' ');
  }
  get productId() {
    return _classPrivateFieldGet(_productId, this);
  }

  get boards() {
    return _classPrivateFieldGet(_boards, this);
  }

  get rowPrice() {
    return _classPrivateFieldGet(_rowPrice, this);
  }
  get drawNumber() {
    return _classPrivateFieldGet(_drawNumber, this);
  }
  get numberOfDraws() {
    return _classPrivateFieldGet(_numberOfDraws, this);
  }
  get systemSize() {
    return this.getRowCount();
  }
  get price() {
    return this.getRowCount() * _classPrivateFieldGet(_rowPrice, this) * _classPrivateFieldGet(_numberOfDraws, this);
  }
  get rowPriceMulti() {
    const [minPrice] = this.productConfig.rowPrice;
    return this.rowPrice / minPrice;
  }
  get priceAsJupiterString() {
    return CurrencyAsJupiterString(this.price);
  }
  get itsProductId() {
    return products.resolveITSProductId(_classPrivateFieldGet(_productId, this));
  }
  get productName() {
    if (_classPrivateFieldGet(_subProductId, this) === subProductIds.EMTIPSET) {
      return 'EM-Tipset';
    } else if (_classPrivateFieldGet(_subProductId, this) === subProductIds.VMTIPSET) {
      return 'VM-Tipset';
    }
    return products.getDisplayName(_classPrivateFieldGet(_productId, this));
  }
  get betProperties() {
    return [..._classPrivateFieldGet(_betProperties, this)];
  }
  get productConfig() {
    return getProductConfig(_classPrivateFieldGet(_productId, this));
  }
  get isOwnRows() {
    return _classPrivateFieldGet(_betProperties, this).has(BetProperties.EXTERNAL_SYSTEMS) || _classPrivateFieldGet(_betProperties, this).has(BetProperties.REDUCE_FREE);
  }

  get isSingleRowsOnly() {
    return this.boards.some(board => board.systemType === SystemType.SROW);
  }
  get isQuickPick() {
    return Boolean(this.quickPickValue);
  }
  get quickPickValue() {
    const isXperten = _classPrivateFieldGet(_betProperties, this).has(BetProperties.XPERTEN);
    if (isXperten) {
      return BetProperties.XPERTEN;
    }
    for (const board of this.boards) {
      const quickPickValue = board.boardProperties.find(boardProp => QuickPick[boardProp]);
      if (quickPickValue) {
        return quickPickValue;
      }
    }
    return null;
  }
}
function _getBoards() {
  if (this.isOwnRows) {
    return this.composeOwnRowsBoards();
  }
  return this.composeBoards();
}
_defineProperty(Bet, "BoardProperties", _objectSpread({}, BoardProperties));
_defineProperty(Bet, "BetProperties", _objectSpread({}, BetProperties));
setGlobal('svs.components.payment.createWager.bet.Bet', Bet);

 })(window);