(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/utils/is-wager.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/utils/is-wager.js');

'use strict';

const {
  Wager
} = svs.components.payment.createWager;
const isWager = item => item instanceof Wager;
setGlobal('svs.components.payment.createWager.utils.isWager', isWager);

 })(window);