(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/bet/board-sportkryss.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/bet/board-sportkryss.js');

'use strict';

const {
  BoardPoolgames
} = svs.components.payment.createWager.bet;
const asStringArray = array => "".concat(array.join(','));
class BoardSportkryss extends BoardPoolgames {
  toString() {
    const outcomeString = this.outcomes.map(board => board.map(selection => asStringArray(selection))).join(',');
    return "B:[".concat(outcomeString, "]");
  }
  compose() {
    const boardData = this.outcomes.join('').split('').map(selection => parseInt(selection, 10));
    return {
      boardData,
      numSelections: this.outcomes.length,
      rowPriceMulti: 1,
      systemType: [this.systemType]
    };
  }
}
setGlobal('svs.components.payment.createWager.bet.BoardSportkryss', BoardSportkryss);

 })(window);