(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/create-wager.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/create-wager.js');
'use strict';

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  COMPETITION,
  GROUP,
  GROUP_B2C
} = svs.components.payment.common.constants.BetProperties;
const {
  WagerError
} = svs.components.payment.common.error;
const {
  Payable,
  constants: {
    PayableType
  }
} = svs.components.payment.common;
const {
  Bet,
  BetScore,
  Bet1X2,
  BetRx,
  BetNumberBoards,
  BetChallenge,
  BetSportkryss,
  BetFulltraff
} = svs.components.payment.createWager.bet;
const {
  BOMBEN,
  MATCHEN,
  MALTIPSET,
  FULLTRAFF,
  T8,
  T8EXTRA,
  T8STRYK,
  EUROPATIPSET,
  STRYKTIPSET,
  POWERPLAY,
  RACING,
  CHALLENGE,
  SPORTKRYSS
} = svs.utils.products.productIds;
const {
  groupTypes
} = svs.components.marketplaceData.constants;

var _bets = new WeakMap();
var _bagType = new WeakMap();
var _activatedDrawId = new WeakMap();
var _commissionPercent = new WeakMap();
var _role = new WeakMap();
var _competitionId = new WeakMap();
var _competitionSignUp = new WeakMap();
var _addonProductId = new WeakMap();
class Wager extends Payable {
  constructor() {
    super(...arguments);
    _classPrivateFieldInitSpec(this, _bets, []);
    _classPrivateFieldInitSpec(this, _bagType, 0);
    _classPrivateFieldInitSpec(this, _activatedDrawId, void 0);
    _classPrivateFieldInitSpec(this, _commissionPercent, void 0);
    _classPrivateFieldInitSpec(this, _role, void 0);
    _classPrivateFieldInitSpec(this, _competitionId, []);
    _classPrivateFieldInitSpec(this, _competitionSignUp, void 0);
    _classPrivateFieldInitSpec(this, _addonProductId, void 0);
  }
  addBet(productId, betBuilderFn) {
    let bet;
    switch (productId) {
      case BOMBEN:
      case MATCHEN:
        bet = new BetScore(productId);
        break;
      case FULLTRAFF:
        bet = new BetFulltraff(productId);
        break;
      case T8:
      case POWERPLAY:
      case T8STRYK:
      case T8EXTRA:
      case EUROPATIPSET:
      case STRYKTIPSET:
        bet = new Bet1X2(productId);
        break;
      case CHALLENGE:
        bet = new BetChallenge(productId);
        break;
      case MALTIPSET:
        bet = new BetNumberBoards(productId);
        break;
      case RACING:
        bet = new BetRx(productId);
        break;
      case SPORTKRYSS:
        bet = new BetSportkryss(productId);
        break;
      default:
        throw new WagerError({
          message: "Unsupported productId: ".concat(productId),
          clientCode: WagerError.ErrorCodes.UNSUPPORTED_VALUE_PRODUCT_ID
        });
    }
    _classPrivateFieldGet(_bets, this).push(bet);
    betBuilderFn(bet);
    return this;
  }

  get getProductBet() {
    return this.bets.find(bet => bet.productId !== SPORTKRYSS);
  }

  setAddonProductId(addonProductId) {
    _classPrivateFieldSet(_addonProductId, this, addonProductId);
    return this;
  }

  get addonProductId() {
    return _classPrivateFieldGet(_addonProductId, this);
  }

  addCompetitionForBet(competitionId, bet, isSignUp) {
    if (!competitionId) {
      throw new WagerError({
        message: 'Missing competition id',
        clientCode: WagerError.ErrorCodes.GENERIC
      });
    }
    if (!(bet instanceof Bet)) {
      throw new WagerError({
        message: "Bet missing or not of type Bet",
        clientCode: WagerError.ErrorCodes.GENERIC
      });
    }
    _classPrivateFieldGet(_competitionId, this).push(competitionId);
    bet.addBetProperty(COMPETITION);
    _classPrivateFieldSet(_competitionSignUp, this, isSignUp);
    return this;
  }

  get isCompetitionSignUp() {
    return _classPrivateFieldGet(_competitionSignUp, this);
  }

  isCompetition() {
    return Boolean(_classPrivateFieldGet(_competitionId, this).length);
  }

  get competitionId() {
    return _classPrivateFieldGet(_competitionId, this);
  }

  compose() {
    var _classPrivateFieldGet2;
    const commissionPercent = (_classPrivateFieldGet2 = _classPrivateFieldGet(_commissionPercent, this)) !== null && _classPrivateFieldGet2 !== void 0 ? _classPrivateFieldGet2 : undefined;
    const bets = _classPrivateFieldGet(_bets, this).map(bet => {
      if (_classPrivateFieldGet(_activatedDrawId, this)) {
        bet.addBetProperty(GROUP);
      }
      if (_classPrivateFieldGet(_role, this) && _classPrivateFieldGet(_role, this) === groupTypes.RETAILER) {
        bet.addBetProperty(GROUP_B2C);
      }
      return bet.compose();
    });
    return {
      crc: 0,
      competitionId: _classPrivateFieldGet(_competitionId, this).length ? _classPrivateFieldGet(_competitionId, this) : undefined,
      marketInfo: 0,
      bagType: _classPrivateFieldGet(_bagType, this),
      commissionPercent,
      bets
    };
  }

  setBagType(type) {
    _classPrivateFieldSet(_bagType, this, type);
    return this;
  }

  get bagType() {
    return _classPrivateFieldGet(_bagType, this);
  }

  setActivatedDrawId(activatedDrawId) {
    _classPrivateFieldSet(_activatedDrawId, this, activatedDrawId);
    return this;
  }

  get activatedDrawId() {
    return _classPrivateFieldGet(_activatedDrawId, this);
  }

  setCommissionPercent(commissionPercent) {
    _classPrivateFieldSet(_commissionPercent, this, commissionPercent);
    return this;
  }

  get commissionPercent() {
    return _classPrivateFieldGet(_commissionPercent, this);
  }

  setRole(role) {
    _classPrivateFieldSet(_role, this, role);
    return this;
  }

  get role() {
    return _classPrivateFieldGet(_role, this);
  }

  getIsMarketplaceWager() {
    return Boolean(_classPrivateFieldGet(_activatedDrawId, this));
  }
  get hasOwnRows() {
    return Boolean(_classPrivateFieldGet(_bets, this).find(bet => bet.isOwnRows));
  }

  getIsRacingWager() {
    return Boolean(_classPrivateFieldGet(_bets, this).find(bet => bet.productId === RACING));
  }

  getProductsInBet() {
    return _classPrivateFieldGet(_bets, this).map(bet => {
      if (bet.productId === SPORTKRYSS) {
        return undefined;
      }
      return bet.productName;
    }).filter(Boolean);
  }

  toString() {
    let logStr = '';
    if (this.getIsMarketplaceWager()) {
      logStr = " S:".concat(_classPrivateFieldGet(_role, this) === groupTypes.PRIVATE ? 'c' : 'r');
      logStr += " AD:".concat(_classPrivateFieldGet(_activatedDrawId, this));
    }
    return "B:".concat(this.bets.length, " ").concat(this.bets.join(', ')).concat(logStr);
  }

  toJSON() {
    return this.compose();
  }

  get bets() {
    return _classPrivateFieldGet(_bets, this);
  }

  getCompetitionBet() {
    return _classPrivateFieldGet(_bets, this).find(bet => bet.betProperties.includes(COMPETITION));
  }

  get price() {
    return _classPrivateFieldGet(_bets, this).reduce((sum, bet) => sum + bet.price, 0);
  }

  get type() {
    return PayableType.WAGER;
  }
}

const createWager = () => new Wager();
setGlobal('svs.components.payment.createWager.createWager', createWager);
setGlobal('svs.components.payment.createWager.Wager', Wager);

 })(window);