(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/bet/board-rx.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/bet/board-rx.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  Board
} = svs.components.payment.createWager.bet;

var _boardData = new WeakMap();
var _rowCount = new WeakMap();
var _systemType = new WeakMap();
class BoardRx extends Board {
  constructor() {
    super(...arguments);
    _classPrivateFieldInitSpec(this, _boardData, {});
    _classPrivateFieldInitSpec(this, _rowCount, 0);
    _classPrivateFieldInitSpec(this, _systemType, void 0);
  }
  addOutcomes(boardData) {
    _classPrivateFieldSet(_boardData, this, boardData[0]);
    _classPrivateFieldGet(_boardData, this).boardProps.forEach(prop => {
      this.addBoardProperty(prop);
    });
    this.setSystemNumber(_classPrivateFieldGet(_boardData, this).systemNum);
    _classPrivateFieldSet(_systemType, this, _classPrivateFieldGet(_boardData, this).systemType[0]);
    return this;
  }

  setRowCount(rowCount) {
    _classPrivateFieldSet(_rowCount, this, rowCount);
    return this;
  }

  getRowCount() {
    return _classPrivateFieldGet(_rowCount, this);
  }

  compose() {
    return _objectSpread(_objectSpread({}, _classPrivateFieldGet(_boardData, this)), {}, {
      boardProps: this.boardProperties,
      systemNum: this.systemNumber,
      systemType: [this.systemType]
    });
  }

  get boardData() {
    return _classPrivateFieldGet(_boardData, this);
  }

  get systemType() {
    return _classPrivateFieldGet(_systemType, this);
  }
}
setGlobal('svs.components.payment.createWager.bet.BoardRx', BoardRx);

 })(window);