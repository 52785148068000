(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/bet/board-poolgames.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/bet/board-poolgames.js');

'use strict';

const {
  Board
} = svs.components.payment.createWager.bet;
const {
  SystemType
} = svs.components.payment.common.constants;
const {
  systems
} = svs.components.sport;
class BoardPoolgames extends Board {
  get systemType() {
    if (this.systemNumber) {
      const system = systems.getSystemFromNumber(this.systemNumber);
      switch (system.systemType) {
        case 'R':
          return SystemType.RSYS;
        case 'U':
          return SystemType.USYS;
        default:
          throw new Error("Unsupported system type: ".concat(system === null || system === void 0 ? void 0 : system.systemType));
      }
    }
    for (const outcomeGroups of this.outcomes) {
      const sum = outcomeGroups.reduce((sum, outcomes) => sum * outcomes.length, 1);
      if (sum !== 1) {
        return SystemType.MSYS;
      }
    }

    return SystemType.SROW;
  }
  toString(isCompact) {
    return super.toString(isCompact);
  }
}
setGlobal('svs.components.payment.createWager.bet.BoardPoolgames', BoardPoolgames);

 })(window);