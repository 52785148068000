(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/bet/board-numboards.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/bet/board-numboards.js');

'use strict';

const {
  BoardPoolgames
} = svs.components.payment.createWager.bet;
const {
  SystemType
} = svs.components.payment.common.constants;
const {
  calculateMsystem
} = svs.components.tipsen.expandedSystems.utils;
const {
  getMaltipsetRSystem
} = svs.components.sport.systems;
const {
  WagerError
} = svs.components.payment.common.error;
const asStringArray = array => "".concat(array.join(','));
class BoardNumboards extends BoardPoolgames {
  addOutcomes(outcomes) {
    if (!Array.isArray(outcomes)) {
      throw new WagerError({
        message: 'outcomes has to be an array',
        clientCode: WagerError.ErrorCodes.GENERIC
      });
    }
    const _outcomes = [...new Set(outcomes)];
    const [min, max] = this.productConfig.eventBounds;
    if (_outcomes.length < min || _outcomes.length > max) {
      const rem = outcomes.length - _outcomes.length;
      let message = "".concat(min, " to ").concat(max, " outcomes is required. \"").concat(_outcomes, "\"");
      if (rem > 0) {
        message += ", ".concat(rem, " duplicates removed.");
      }
      message += " (length: ".concat(_outcomes.length, ") is not valid.");
      throw new WagerError({
        message,
        clientCode: WagerError.ErrorCodes.GENERIC
      });
    }
    _outcomes.forEach(selection => super.addOutcomes([selection]));
    return this;
  }

  getBoardData() {
    return this.outcomes.map(selection => {
      const newSelection = [...selection[0]];
      return newSelection[0];
    });
  }

  getRowCount() {
    const numSelections = this.getBoardData().length;
    switch (this.systemType) {
      case SystemType.MSYS:
        return calculateMsystem(numSelections);
      case SystemType.RSYS:
        return getMaltipsetRSystem(numSelections);
      default:
        return super.getRowCount();
    }
  }

  setSystemNumber(systemNumber) {
    if (!getMaltipsetRSystem(systemNumber)) {
      throw new WagerError({
        message: "Unsupported systemNumber: ".concat(systemNumber),
        clientCode: WagerError.ErrorCodes.UNSUPPORTED_VALUE_SYSTEM_NUMBER
      });
    }
    super.setSystemNumber(systemNumber);
    return this;
  }

  get systemType() {
    const numSelections = this.getBoardData().length;
    const [min, max] = this.productConfig.eventBounds;
    if (numSelections > min && numSelections <= max) {
      if (!this.systemNumber) {
        return SystemType.MSYS;
      }
      if (this.systemNumber) {
        return SystemType.RSYS;
      }
    }
    return super.systemType;
  }

  composeOwnRows() {
    const outcomeString = this.outcomes.map(signArray => signArray.map(sign => {
      if (sign < 10) {
        return "0".concat(sign);
      }
      return "".concat(sign);
    })).join('');
    return outcomeString;
  }

  compose() {
    const boardData = this.getBoardData();
    const isSystem = this.systemType === SystemType.MSYS || this.systemType === SystemType.RSYS;
    return {
      rowPriceMulti: 1,
      boardData,
      numSelections: this.outcomes.length,
      participation: '0',
      systemNum: isSystem ? this.outcomes.length : 0,
      boardProps: this.boardProperties,
      systemType: [this.systemType]
    };
  }

  toString() {
    const logString = super.toString();
    const outcomeString = this.outcomes.map(board => board.map(selection => asStringArray(selection))).join(',');
    const logEntries = ["B:[".concat(outcomeString, "]"), logString];
    return logEntries.join(' ');
  }
}
setGlobal('svs.components.payment.createWager.bet.BoardNumboards', BoardNumboards);

 })(window);