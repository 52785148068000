(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/bet/board.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/bet/board.js');

'use strict';

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  WagerError
} = svs.components.payment.common.error;
const {
  BoardProperties
} = svs.components.payment.common.constants;
const {
  getProductConfig
} = svs.components.payment.createWager.configs;
const {
  NotImplementedError
} = svs.components.payment.common.error;
const {
  systems
} = svs.components.sport;
const {
  bitmapToPositions
} = svs.components.payment.createWager.utils;
const asArray = input => typeof input === 'number' ? bitmapToPositions(input) : input;
var _boardProperties = new WeakMap();
var _outcomes = new WeakMap();
var _productId = new WeakMap();
var _systemNumber = new WeakMap();
class Board {
  constructor(productId) {
    _classPrivateFieldInitSpec(this, _boardProperties, new Set());
    _classPrivateFieldInitSpec(this, _outcomes, []);
    _classPrivateFieldInitSpec(this, _productId, 0);
    _classPrivateFieldInitSpec(this, _systemNumber, 0);
    _classPrivateFieldSet(_productId, this, productId);
  }

  addOutcomes() {
    const [, upperOutcomeBound] = this.productConfig.outcomeBounds;
    for (var _len = arguments.length, outcomeGroups = new Array(_len), _key = 0; _key < _len; _key++) {
      outcomeGroups[_key] = arguments[_key];
    }
    const sortedOutcomeGroups = outcomeGroups.map(outcomes => [...new Set(asArray(outcomes).slice().sort((a, b) => {
      if (typeof a !== 'number' || typeof b !== 'number') {
        throw new WagerError({
          message: 'Outcome should be of type number',
          clientCode: WagerError.ErrorCodes.INCOMPLETE_BET
        });
      }
      return a - b;
    }))]);
    for (const outcomes of sortedOutcomeGroups) {
      const [largestSelection] = outcomes.slice(-1);
      const [lowestSelection] = outcomes;
      if (largestSelection > upperOutcomeBound || lowestSelection < 0) {
        throw new WagerError({
          message: "Unsupported selection, exceeds max cap or is negative: [".concat(lowestSelection, " ").concat(largestSelection, "](").concat(upperOutcomeBound, ")"),
          clientCode: WagerError.ErrorCodes.INCOMPLETE_BET
        });
      }
    }
    _classPrivateFieldGet(_outcomes, this).push(sortedOutcomeGroups);
    return this;
  }

  setSystemNumber(systemNumber) {
    _classPrivateFieldSet(_systemNumber, this, systemNumber);
    return this;
  }

  getRowCount() {
    if (this.systemNumber) {
      const system = systems.getSystemFromNumber(this.systemNumber);
      return system.rows.length;
    }
    return _classPrivateFieldGet(_outcomes, this).reduce((sum, outcomeGroup) => outcomeGroup.reduce((rowSum, outcomes) => outcomes.length * (rowSum || 1), 0) * (sum || 1), 0);
  }
  get outcomes() {
    return _classPrivateFieldGet(_outcomes, this);
  }

  addBoardProperty(boardProperty) {
    const validProperties = Object.values(BoardProperties);
    if (!validProperties.includes(boardProperty)) {
      throw new WagerError({
        message: "Invalid board property: ".concat(boardProperty, ", valid: [").concat(validProperties.join(', '), "]"),
        clientCode: WagerError.ErrorCodes.UNSUPPORTED_VALUE_BOARD_PROP
      });
    }
    _classPrivateFieldGet(_boardProperties, this).add(boardProperty);
    return this;
  }

  compose() {
    throw new NotImplementedError();
  }

  composeOwnRows() {
    throw new NotImplementedError();
  }

  composeUOutcomes() {
    throw new NotImplementedError();
  }

  toString() {

    const logEntries = ["S:".concat(this.systemType, "(").concat(this.systemNumber || this.getRowCount(), ")"), "P:".concat(this.boardProperties.join('|'))];
    return logEntries.join(' ');
  }
  get isQuickPick() {
    return this.boardProperties.some(() => [BoardProperties.WEIGHTEDQP, BoardProperties.MODIFIEDQP, BoardProperties.PARTIALQP]);
  }
  get boardProperties() {
    return [..._classPrivateFieldGet(_boardProperties, this)];
  }
  get productId() {
    return _classPrivateFieldGet(_productId, this);
  }
  get systemNumber() {
    return _classPrivateFieldGet(_systemNumber, this);
  }
  get systemType() {
    throw new NotImplementedError();
  }
  get productConfig() {
    return getProductConfig(_classPrivateFieldGet(_productId, this));
  }
}
setGlobal('svs.components.payment.createWager.bet.Board', Board);

 })(window);