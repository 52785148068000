(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/bet/board-score.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/bet/board-score.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  WagerError
} = svs.components.payment.common.error;
const {
  BoardPoolgames
} = svs.components.payment.createWager.bet;
const {
  SystemType
} = svs.components.payment.common.constants;
const {
  utils
} = svs.components.payment.createWager;
const {
  getCartesianProducts
} = svs.components.tipsen.expandedSystems;
const {
  ScoreReduction
} = svs.components.payment.common.constants;
const asStringArray = array => "[".concat(array.join(','), "]");
const filterFn = (homeValue, awayValue) => homeValue - awayValue;
const homeFilter = function (homeValue) {
  let awayValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return filterFn(homeValue, awayValue) > 0;
};
const drawFilter = function (homeValue) {
  let awayValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return filterFn(homeValue, awayValue) === 0;
};
const awayFilter = function (homeValue) {
  let awayValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return filterFn(homeValue, awayValue) < 0;
};
const scoreFilterFnMap = {
  [ScoreReduction.HOME]: homeFilter,
  [ScoreReduction.DRAW]: drawFilter,
  [ScoreReduction.AWAY]: awayFilter
};
const fullReduction = Object.freeze([ScoreReduction.HOME, ScoreReduction.DRAW, ScoreReduction.AWAY]);
var _reductions = new WeakMap();
class BoardScore extends BoardPoolgames {
  constructor() {
    super(...arguments);
    _classPrivateFieldInitSpec(this, _reductions, []);
  }
  addReduction(eventNumber, reductions) {
    const reductionArray = typeof reductions === 'number' ? utils.bitmapToPositions(reductions).map(binIndex => fullReduction[binIndex]) : [].concat(reductions);
    const isValidReduction = reductionArray.every(reduce => fullReduction.includes(reduce));
    if (!isValidReduction) {
      throw new WagerError({
        message: "Unsupported reduction value in: [".concat(reductionArray.join(', '), "]"),
        clientCode: WagerError.ErrorCodes.UNSUPPORTED_VALUE_REDUCTION
      });
    }
    _classPrivateFieldGet(_reductions, this)[eventNumber - 1] = reductionArray;
    return this;
  }

  getRowCount() {
    if (this.systemType === SystemType.MSYS) {
      return super.getRowCount();
    }
    if (this.systemType === SystemType.SROW) {
      return 1; 
    }
    const reductions = this.reductions;
    const rowCounts = this.outcomes.reduce((sum, outcomeGroup, eventIndex) => {
      const currentReduction = reductions[eventIndex];
      const filterFns = currentReduction ? currentReduction.map(reduction => scoreFilterFnMap[reduction]) : Object.values(scoreFilterFnMap);
      const allSROW = getCartesianProducts(...outcomeGroup);
      const filteredSROW = allSROW.filter(result => filterFns.some(filterFn => filterFn(...result)));
      return filteredSROW.length * (sum || 1);
    }, 0);
    return rowCounts;
  }

  toString(isCompact) {
    const logString = super.toString(isCompact);
    const logEntries = [logString];
    if (!isCompact) {
      const outcomeString = this.outcomes.map(board => asStringArray(board.map(selection => asStringArray(selection)))).join(',');
      const reductionString = this.reductions.map((reduction, eventIndex) => "".concat(eventIndex + 1, "#").concat(reduction.map(r => fullReduction.indexOf(r)).join(',')));
      logEntries.unshift("B:".concat(outcomeString));
      logEntries.push("Rd:".concat(reductionString));
    }
    return logEntries.join(' ');
  }
  get reductions() {
    if (this.systemType !== SystemType.RSYS) {
      return [];
    }
    return this.outcomes.map((_, index) => {
      const reduction = _classPrivateFieldGet(_reductions, this)[index];
      if (reduction) {
        return reduction;
      }
      return fullReduction;
    });
  }
  get systemType() {
    const isReduced = _classPrivateFieldGet(_reductions, this).some(reduction => reduction.length !== fullReduction.length);
    if (isReduced) {
      return SystemType.RSYS;
    }
    return super.systemType;
  }

  composeOwnRows() {
    var _productConfig$outcom;
    const outcomes = [];
    const productConfig = this.productConfig;
    const maxOutComeBound = (productConfig === null || productConfig === void 0 || (_productConfig$outcom = productConfig.outcomeBounds) === null || _productConfig$outcom === void 0 ? void 0 : _productConfig$outcom[1]) || 10;
    for (const eventOutcome of this.outcomes) {
      for (const outcome of eventOutcome) {
        outcomes.push(parseInt(outcome[0], 10) === maxOutComeBound ? ['F'] : outcome);
      }
    }
    return outcomes.join('');
  }

  compose() {
    const boardData = this.outcomes.reduce((scores, outcomeGroup) => {
      const score = outcomeGroup.reduce((outcomes, _outcomes) => outcomes.concat([_outcomes.map(outcome => outcome.toString())]), []);
      return scores.concat(score);
    }, []);
    const rSysData = this.reductions.length ? this.reductions : undefined;
    const additionalBoardProperties = {};
    if (this.boardProperties.length) {
      additionalBoardProperties.boardProps = this.boardProperties;
    }
    return _objectSpread(_objectSpread({
      boardData
    }, additionalBoardProperties), {}, {
      systemType: [this.systemType],
      rSysData,
      rowPriceMulti: 1
    });
  }
}
_defineProperty(BoardScore, "Reductions", _objectSpread({}, ScoreReduction));
setGlobal('svs.components.payment.createWager.bet.BoardScore', BoardScore);

 })(window);