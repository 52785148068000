(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/bet/board-fulltraff.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/bet/board-fulltraff.js');

'use strict';

const {
  BoardScore
} = svs.components.payment.createWager.bet;
class BoardFulltraff extends BoardScore {
  composeOwnRows() {
    const hexString = this.outcomes.map(signArray => signArray.reduce((acc, signs) => {
      signs.flat().map(signIndex =>
      acc |= 1 << signIndex);
      return acc;
    }, 0)
    .toString(16)
    .padStart(2, '0')).join('').toUpperCase();
    return hexString;
  }
}
setGlobal('svs.components.payment.createWager.bet.BoardFulltraff', BoardFulltraff);

 })(window);