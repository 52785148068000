(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/bet/bet-score.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/bet/bet-score.js');
'use strict';

const {
  Bet
} = svs.components.payment.createWager.bet;
const {
  SystemType,
  BetProperties
} = svs.components.payment.common.constants;
const {
  productIds
} = svs.utils.products;

class BetScore extends Bet {
  getSystemLabel() {

    const systemType = this.boards[0].systemType;
    if (systemType === SystemType.RSYS) {
      return "R ".concat(this.getRowCount());
    }
    if (systemType === SystemType.MSYS) {
      return "M ".concat(this.getRowCount());
    }
    if (systemType === SystemType.SROW) {
      return "S ".concat(this.getRowCount());
    }
    return super.getSystemLabel();
  }

  getRowCount() {
    if (this.betProperties.includes(BetProperties.XPERTEN)) {
      return this.boards.length;
    }
    return super.getRowCount();
  }

  composeXperten() {
    const eventResults = [];
    for (const board of this.boards) {
      for (const [eventIndex, outcomeGroup] of Object.entries(board.outcomes)) {
        const outcomePair = Array.prototype.concat.apply([], outcomeGroup);
        const eventPairs = eventResults[eventIndex] || [];
        const outcomePairStr = outcomePair.join();
        const isNotUnique = eventPairs.some(pair => pair.join() === outcomePairStr);
        eventResults[eventIndex] = eventPairs.concat(isNotUnique ? [] : [outcomePair]);
      }
    }
    return {
      eventResults
    };
  }

  composeOwnRowsBoards() {
    const boards = this.boards.map(board => board.composeOwnRows());
    const numMatchesPerBoard = this.boards[0].outcomes.length;
    return {
      erScoreBoardData: {
        boards,
        numMatchesPerBoard
      }
    };
  }
  composeBoards() {
    if (this.betProperties.includes(BetProperties.XPERTEN) &&
    [productIds.BOMBEN, productIds.MATCHEN].includes(this.productId)) {
      return {
        expertenBoardData: this.composeXperten()
      };
    }
    const scoreBoards = this.boards.map(board => board.compose());
    return {
      scoreBoards
    };
  }
}
setGlobal('svs.components.payment.createWager.bet.BetScore', BetScore);

 })(window);