(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/bet/bet-challenge.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/bet/bet-challenge.js');
'use strict';

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  Bet1X2
} = svs.components.payment.createWager.bet;

var _rowPriceMulti = new WeakMap();
class BetChallenge extends Bet1X2 {
  constructor() {
    super(...arguments);
    _classPrivateFieldInitSpec(this, _rowPriceMulti, 1);
  }
  setRowPriceMulti(rowPriceMulti) {
    _classPrivateFieldSet(_rowPriceMulti, this, rowPriceMulti);
    return this;
  }
  get rowPriceMulti() {
    return _classPrivateFieldGet(_rowPriceMulti, this);
  }
}
setGlobal('svs.components.payment.createWager.bet.BetChallenge', BetChallenge);

 })(window);