(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/bet/bet-numboards.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/bet/bet-numboards.js');
'use strict';

const {
  Bet
} = svs.components.payment.createWager.bet;
const {
  SystemType
} = svs.components.payment.common.constants;
const {
  systems
} = svs.components.sport;

class BetNumberBoards extends Bet {
  getSystemLabel() {
    if (this.systemNumber) {
      return systems.getSystemKeyFromNumber(this.systemNumber);
    }
    const board = this.boards[0];
    const systemType = board.systemType;

    const rows = board.getBoardData().length;
    if (systemType === SystemType.RSYS) {
      return "R ".concat(rows);
    }
    if (systemType === SystemType.MSYS) {
      return "M ".concat(rows);
    }
    if (systemType === SystemType.SROW) {
      return "S ".concat(rows);
    }
    return super.getSystemLabel();
  }

  composeOwnRowsBoards() {
    const boards = this.boards.map(board => board.composeOwnRows());
    return {
      erNumberBoardData: {
        boards
      }
    };
  }
  composeBoards() {
    const numberBoards = this.boards.map(board => board.compose());
    return {
      numberBoards
    };
  }
}
setGlobal('svs.components.payment.createWager.bet.BetNumberBoards', BetNumberBoards);

 })(window);