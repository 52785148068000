(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/bet/bet-rx.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/bet/bet-rx.js');
'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  Bet
} = svs.components.payment.createWager.bet;
var _subProductId = new WeakMap();
var _systemType = new WeakMap();
var _rowPriceMulti = new WeakMap();
class BetRx extends Bet {
  constructor() {
    super(...arguments);
    _classPrivateFieldInitSpec(this, _subProductId, void 0);
    _classPrivateFieldInitSpec(this, _systemType, void 0);
    _classPrivateFieldInitSpec(this, _rowPriceMulti, void 0);
  }
  setSubProductId(subProductId) {
    _classPrivateFieldSet(_subProductId, this, subProductId);
    return this;
  }
  setSystemType(systemType) {
    _classPrivateFieldSet(_systemType, this, systemType);
    return this;
  }
  setRowPriceMulti(rowPriceMulti) {
    _classPrivateFieldSet(_rowPriceMulti, this, rowPriceMulti);
    return this;
  }
  get rowPriceMulti() {
    return _classPrivateFieldGet(_rowPriceMulti, this);
  }
  getSystemLabel() {
    return _classPrivateFieldGet(_systemType, this);
  }

  composeBoards() {
    const rxBoards = this.boards.map(board => board.compose());
    return {
      rxBoards
    };
  }

  compose() {
    return _objectSpread(_objectSpread({}, super.compose()), {}, {
      subProductId: _classPrivateFieldGet(_subProductId, this)
    });
  }

  get price() {
    return this.getRowCount() * (this.rowPrice * _classPrivateFieldGet(_rowPriceMulti, this));
  }

  get productName() {
    return 'Hästar';
  }
}
setGlobal('svs.components.payment.createWager.bet.BetRx', BetRx);

 })(window);