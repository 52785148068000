(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/utils/bitwise.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/utils/bitwise.js');
'use strict';

const getBitmap = cb => bitmap => bitmap.toString(2).split('').reverse().map(Number).reduce((arr, val, pos) => {
  if (val) {
    return arr.concat(cb(pos));
  }
  return arr;
}, []);

const bitmapToPositions = getBitmap(pos => pos);

const bitmapToArray = getBitmap(pos => 2 ** pos);

const oneXTwoToBinary = input => {
  const lookup = ['1', 'X', '2'];
  const binary = input.split('').reduce((acc, v) => acc + 2 ** lookup.indexOf(v), 0);
  return binary;
};
setGlobal('svs.components.payment.createWager.utils', {
  bitmapToArray,
  bitmapToPositions,
  oneXTwoToBinary
});

 })(window);