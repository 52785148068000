(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/bet/bet-fulltraff.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/bet/bet-fulltraff.js');

'use strict';

const {
  BetScore
} = svs.components.payment.createWager.bet;

class BetFulltraff extends BetScore {
  composeOwnRowsBoards() {
    const boards = this.boards.map(board => board.composeOwnRows());
    return {
      erScoreBoardData: {
        boards,
        simpleRowsOnly: this.isSingleRowsOnly
      }
    };
  }
}
setGlobal('svs.components.payment.createWager.bet.BetFulltraff', BetFulltraff);

 })(window);