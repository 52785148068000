(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/configs/get-product-config.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/configs/get-product-config.js');
'use strict';

const {
  productIds
} = svs.utils.products;

const ProductConfig = Object.freeze({
  [productIds.BOMBEN]: {
    groups: 2,
    outcomeBounds: [10, 10],
    eventBounds: [2, 4],
    rowPrice: [1, 500],
    numberOfDraws: [1]
  },
  [productIds.MATCHEN]: {
    groups: 2,
    outcomeBounds: [255, 255],
    eventBounds: [1, 1],
    rowPrice: [1, 500],
    numberOfDraws: [1]
  },
  [productIds.FULLTRAFF]: {
    groups: 1,
    outcomeBounds: [7, 7],
    eventBounds: [13, 13],
    rowPrice: [1, 1],
    numberOfDraws: [1, 5, 10]
  },
  [productIds.CHALLENGE]: {
    groups: 1,
    outcomeBounds: [2, 3],
    eventBounds: [6, 8],
    rowPrice: [5, 50],
    numberOfDraws: [1]
  },
  [productIds.STRYKTIPSET]: {
    groups: 1,
    outcomeBounds: [3, 3],
    eventBounds: [13, 13],
    rowPrice: [1, 1],
    numberOfDraws: [1, 5, 10]
  },
  [productIds.EUROPATIPSET]: {
    groups: 1,
    outcomeBounds: [3, 3],
    eventBounds: [13, 13],
    rowPrice: [1, 1],
    numberOfDraws: [1]
  },
  [productIds.T8]: {
    groups: 1,
    outcomeBounds: [3, 3],
    eventBounds: [8, 8],
    rowPrice: [1, 10],
    numberOfDraws: [1]
  },
  [productIds.POWERPLAY]: {
    groups: 1,
    outcomeBounds: [3, 3],
    eventBounds: [8, 8],
    rowPrice: [1, 10],
    numberOfDraws: [1]
  },
  [productIds.RACING]: {
    groups: 1,
    outcomeBounds: [],
    eventBounds: [],
    rowPrice: [1, 1],
    numberOfDraws: [1]
  },
  [productIds.T8EXTRA]: {
    groups: 1,
    outcomeBounds: [3, 3],
    eventBounds: [8, 8],
    rowPrice: [1, 10],
    numberOfDraws: [1]
  },
  [productIds.T8STRYK]: {
    groups: 1,
    outcomeBounds: [3, 3],
    eventBounds: [8, 8],
    rowPrice: [1, 10],
    numberOfDraws: [1]
  },
  [productIds.MALTIPSET]: {
    groups: 1,
    outcomeBounds: [1, 30],
    eventBounds: [8, 14],
    rowPrice: [2.5, 2.5],
    numberOfDraws: [1, 5, 10]
  },
  [productIds.SPORTKRYSS]: {
    groups: 1,
    outcomeBounds: [9, 9],
    eventBounds: [7, 7],
    rowPrice: [1, 3],
    numberOfDraws: [1, 5, 10]
  }
});

const getProductConfig = productId => {
  const productConfig = ProductConfig[productId];
  if (!productConfig) {
    throw new Error("Unsupported product id: ".concat(productId));
  }
  return productConfig;
};
setGlobal('svs.components.payment.createWager.configs.ProductConfig', ProductConfig);
setGlobal('svs.components.payment.createWager.configs.getProductConfig', getProductConfig);

 })(window);