(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-wager/assets/bet/bet-sportkryss.js') >= 0) return;  svs.modules.push('/components/payment/create-wager/assets/bet/bet-sportkryss.js');
'use strict';

const {
  Bet
} = svs.components.payment.createWager.bet;

class BetSportkryss extends Bet {
  getSystemLabel() {
    return "S ".concat(this.getRowCount());
  }
  composeBoards() {
    const boards = this.boards.map(board => board.compose());
    return {
      jokerBoards: boards
    };
  }

  toString() {
    const boardsStrings = this.boards.map(board => board.toString(this.isOwnRows));

    const logEntries = ["pID:".concat(this.productId), "".concat(boardsStrings.join('|')), "RP:".concat(this.rowPrice)];
    return logEntries.join(' ');
  }
  get price() {
    return super.price * 10; 
  }
}
setGlobal('svs.components.payment.createWager.bet.BetSportkryss', BetSportkryss);

 })(window);